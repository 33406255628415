import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment,
} from "@material-ui/core";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Update,
} from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import { useState, useEffect } from "react";
import { useBudgetFormContext } from "../../../context/BudgetFormContext";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#034EA2",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F4F6F8",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const HeadColumn = ({ title, subTitle, align }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: align,
      flexDirection: "column",
      alignItems: align,
    }}
  >
    <span
      style={{
        fontWeight: 700,
        fontSize: 14,
        color: "#ffff",
        lineHeight: 1,
      }}
    >
      {title}
    </span>
    {subTitle && (
      <span
        style={{
          fontWeight: 500,
          fontSize: 12,
          color: "#ffff",
        }}
      >
        {subTitle}
      </span>
    )}
  </Box>
);

const format = (price) =>
  new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(price);

const FormSelection = () => {
  const { selectedPrices, budgets, addToBudget, setExtras, defaultDiscount, setDiscountInput, setPercDiscount } =
    useBudgetFormContext();
  const [open, setOpen] = useState(true);
  const [flightTax, setFlight] = useState(0);
  const [boardingTax, setBoardingTax] = useState(0);
  const [transferTax, setTransferTax] = useState(0);
  const [additionalTax, setAdditionalTax] = useState(0);
  const [totalExtras, setTotalExtras] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [percDiscount, setPercDiscountLocal] = useState(0);

  const recalculatePrices = (
    flightTax,
    transferTax,
    additionalTax,
    boardingTax,
  ) => {
    setExtras(flightTax + transferTax + additionalTax + boardingTax);
    setTotalExtras(flightTax + transferTax + additionalTax + boardingTax);
    setDiscountInput(discount);
    setPercDiscount(percDiscount);
  };

  useEffect(() => {
    setAdditionalTax(0);
    setTransferTax(0);
    setBoardingTax(0);
    setFlight(0);
    setExtras(0);
    setTotalExtras(0);

  }, [setExtras]);

  useEffect(() => {
      if (percDiscount > 0) setDiscount(0);
  }, [percDiscount])

    useEffect(() => {
          if (discount > 0) setPercDiscountLocal(0);
      }, [discount])

  return (
    <Grid container={true} spacing={3}>
      <Grid item md={12} xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignContent: "space-between",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                fontWeight: 600,
                fontSize: 18,
                fontFamily: "Poppins",
                color: "#034EA2",
              }}
            >
              Seleção atual
            </span>
            <span
              style={{
                fontWeight: 600,
                fontSize: 15,
                fontFamily: "Poppins",
                color: "#868E96",
              }}
            >
              Resumo dos itens selecionados
            </span>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
          >
            <IconButton onClick={(_) => setOpen(!open)}>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
        </Box>
      </Grid>
      <Divider />
      <Grid item md={8} xs={12}>
        <Collapse in={open}>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <StyledTableCell align={"left"}>
                  <HeadColumn title={"Acomodação"} align={"flex-left"} />
                </StyledTableCell>
                <StyledTableCell align={"right"}>
                  <HeadColumn
                    title={"Total Diárias"}
                    subTitle={"Sem Taxas"}
                    align={"flex-right"}
                  />
                </StyledTableCell>

                  <StyledTableCell align={"right"}>
                  <HeadColumn
                    title={"Total Diárias"}
                    subTitle={"c/ Taxas"}
                    align={"flex-right"}
                  />
                </StyledTableCell>

                  <StyledTableCell align={"right"}>
                  <HeadColumn
                    title={"Total Diárias"}
                    subTitle={"c/ Taxas + Desc. à vista"}
                    align={"flex-right"}
                  />
                </StyledTableCell>


                <StyledTableCell align={"right"}>
                  <HeadColumn
                    title={"Total Pacote"}
                    subTitle={"Diárias + extras + taxas"}
                    align={"flex-right"}
                  />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedPrices.map((price) => (
                <StyledTableRow key={price.acommodation_description}>
                  <TableCell>{price.acommodation_description}</TableCell>
                  <TableCell align={"right"}>
                    {format(price.total_daily_without_tax)}
                  </TableCell>
                  <TableCell align={"right"}>
                    {format(price.total_daily_with_tax)}
                  </TableCell>
                    <TableCell align={"right"}>
                        <strong>{format(price.total_daily_with_tax_discount)}</strong>
                    </TableCell>
                  <TableCell align={"right"}>
                      <strong>{format(price.total_cost_accomodation)}</strong>
                  </TableCell>


                </StyledTableRow>
              ))}

              <StyledTableRow>
                <TableCell colSpan={3}>
                  <Box
                    sx={{
                      display: "flex",
                      alignContent: "space-between",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: 13,
                        fontFamily: "Poppins",
                        color: "#ADB5BD",
                      }}
                    >
                      Adicione este item de orçamento a proposta atual
                    </span>
                    <Button
                      sx={{ textTransform: "capitalize", color: "#fff" }}
                      color="fifth"
                      variant="contained"
                      onClick={addToBudget}
                      disabled={
                        selectedPrices.length === 0 || budgets.length === 3
                      }
                    >
                      Adicionar Orçamento
                    </Button>
                  </Box>
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
          <Box
            sx={{
              m: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Update sx={{ color: "#2F9E44" }} />
            <span
              style={{
                fontWeight: 600,
                fontSize: 15,
                fontFamily: "Poppins",
                color: "#2F9E44",
              }}
            >
              Atualização automática conforme seleção de itens
            </span>
          </Box>

            <Box
                sx={{
                    m: 1,
                    mt: 3,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                }}
            >
                <span
                    style={{
                        fontWeight: 600,
                        fontSize: 15,
                        fontFamily: "Poppins",
                        color: "#000000",
                    }}
                >
                    Pagamentos à vista possuem {defaultDiscount}% de Desconto
                </span>
            </Box>
        </Collapse>
      </Grid>
      <Grid item md={4} xs={12}>
        <Collapse in={open}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              maxWidth: 400,
            }}
          >
            <FormControl
              fullWidth
              variant="filled"
              style={{ marginBottom: "15px" }}
            >
              <InputLabel htmlFor="flight">Tarifa Aéreo Familia</InputLabel>
              <FilledInput
                id="flight"
                value={flightTax}
                onChange={(e) => setFlight(Number(e.target.value))}
                startAdornment={
                  <InputAdornment position="start">R$</InputAdornment>
                }
              />
            </FormControl>

            <FormControl
              fullWidth
              variant="filled"
              style={{ marginBottom: "15px" }}
            >
              <InputLabel htmlFor="boarding">
                Tarifa Embarque Familia
              </InputLabel>
              <FilledInput
                id="boarding"
                value={boardingTax}
                onChange={(e) => setBoardingTax(Number(e.target.value))}
                startAdornment={
                  <InputAdornment position="start">R$</InputAdornment>
                }
              />
            </FormControl>

            <FormControl
              fullWidth
              variant="filled"
              style={{ marginBottom: "15px" }}
            >
              <InputLabel htmlFor="transfer">Transfer Familia</InputLabel>
              <FilledInput
                id="transfer"
                value={transferTax}
                onChange={(e) => setTransferTax(Number(e.target.value))}
                startAdornment={
                  <InputAdornment position="start">R$</InputAdornment>
                }
              />
            </FormControl>

            <FormControl
              fullWidth
              variant="filled"
              style={{ marginBottom: "15px" }}
            >
              <InputLabel htmlFor="additional">
                Adicionais (requerimento/ingresso/spa)
              </InputLabel>
              <FilledInput
                id="additional"
                value={additionalTax}
                onChange={(e) => setAdditionalTax(Number(e.target.value))}
                startAdornment={
                  <InputAdornment position="start">R$</InputAdornment>
                }
              />
            </FormControl>

              {/* <FormControl
                  fullWidth
                  variant="filled"
                  style={{ marginBottom: "15px" }}
              >
                  <InputLabel htmlFor="transfer">Valor do Desconto</InputLabel>
                  <FilledInput
                      id="discount"
                      value={discount}
                      onChange={(e) => setDiscount(Number(e.target.value))}
                      startAdornment={
                          <InputAdornment position="start">R$</InputAdornment>
                      }
                  />
              </FormControl>

              <FormControl
                  fullWidth
                  variant="filled"
                  style={{ marginBottom: "15px" }}
              >
                  <InputLabel htmlFor="transfer">Percentual de Desconto</InputLabel>
                  <FilledInput
                      id="percDiscount"
                      value={percDiscount}
                      onChange={(e) => setPercDiscountLocal(Number(e.target.value))}
                      startAdornment={
                          <InputAdornment position="start">%</InputAdornment>
                      }
                  />
              </FormControl> */}

              <FormControl
                  fullWidth
                  variant="filled"
                  style={{ marginBottom: "15px" }}
              >
                  <InputLabel htmlFor="discount">
                      Total de Extras
                  </InputLabel>
                  <FilledInput
                      id="discount"
                      value={totalExtras || 0}
                      disabled={true}
                      startAdornment={
                          <InputAdornment position="start">R$</InputAdornment>
                      }
                  />
              </FormControl>



              <Button
              color="primary"
              variant="contained"
              onClick={() => {
                recalculatePrices(
                  flightTax,
                  transferTax,
                  additionalTax,
                  boardingTax,
                );
              }}
            >
              Adicionar extras / desconto
            </Button>
          </Box>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default FormSelection;
