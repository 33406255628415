import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useLayoutEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";
import { differenceInDays, format } from "date-fns";
import { useBudgetFormContext } from "../../../context/BudgetFormContext";
import { getDayOfWeek, stringToDate } from "../../../mapping/taxMapping";

const HeadColumn = ({ date }) => {
  const parsedDate = stringToDate(date);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <span
        style={{
          fontWeight: 700,
          fontSize: 13,
          color: "#343A40",
          lineHeight: 1,
        }}
      >
        {getDayOfWeek(parsedDate)}
      </span>
      <span
        style={{
          fontWeight: 500,
          fontSize: 12,
          color: "#ADB5BD",
        }}
      >
        {format(parsedDate, "dd/MM")}
      </span>
    </Box>
  );
};

const FormList = () => {
  const _ = require("lodash");
  const {
    data,
    params,
    selectedDates,
    setSelectedDates,
    setCheckedRules,
    checkedRules,
  } = useBudgetFormContext();

  const getColor = (date, item) => {
    if (item && item.restriction_type) {
      switch (item.restriction_type) {
        case 1:
          return "#d9d9d9"; //"gray"; stop
        case 2:
          return "#e0c3fc"; //"purple"; fecho
        case 3:
          return  "#c7f9cc";  //"green"; fecho_in
        case 4:
          return "#f7a9a8"; //"pink"; fecho_out
        default:
          return null;
      }
    }

    if (selectedDates.includes(date)) return "#FFEC99";

    return "#fff";
  };

  useLayoutEffect(() => {
    const container = document.querySelector("#scrollbar");
    if (!container) return;
    
    const diffDays =
      parseInt(format(params.check_out, "dd")) -
      parseInt(format(params.check_in, "dd")) +
      1;

    container.scrollLeft = 480 + diffDays * 30;
  }, [data.dates]);

  const handleClickColumn = (index) => {
    const diff = differenceInDays(params.check_out, params.check_in);
    const dates = _.take(_.drop(data.dates, index), diff);
    setSelectedDates(dates);
  };

  if (Object.keys(data.tax).length === 0) return null;

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ }}>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell height={49.5}>
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: 14,
                    color: "#343A40",
                    lineHeight: 1,
                  }}
                >
                  Tarifário
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.tax.map(({ acommodation_type }) => (
              <TableRow key={acommodation_type}>
                <TableCell padding={"none"} sx={{ padding: "6px 0 ", whiteSpace: "nowrap"  }}>
                  <p style={{paddingRight: '3px'}}>{acommodation_type}</p>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell height={49.5}>
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: 14,
                    color: "#343A40",
                    lineHeight: 1,
                  }}
                >
                  Valores (R$)
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.prices.map(({ acommodation_description }) => (
              <TableRow key={acommodation_description}>
                <TableCell padding={"none"} sx={{ padding: "6px 0", fontSize: 11, height: 53, whiteSpace: "nowrap" }}>
                  {acommodation_description}
                  <br />
                  <br />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          <TableHead>
            <TableRow>
              <TableCell height={49.5}>
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: 14,
                    color: "#343A40",
                    lineHeight: 1,
                  }}
                >
                  Regras
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {data.rules.map(({ rule_description, flag_cumulative }) => (
            ( rule_description !== "." ? (
              <TableRow key={rule_description}>
                <TableCell
                  height={52.5}
                  padding={"none"}
                  sx={{ minWidth: 220, padding: "6px 0" }}
                >
                  {rule_description} &nbsp;
                  <Checkbox
                    sx={{ padding: 0 }}
                    checked={checkedRules[rule_description]?.checked || false}
                    onChange={(_) => {
                      const checks = { ...checkedRules };
                      // if (flag_cumulative === "N") {
                      //   Object.keys(checks).forEach((rule) => {
                      //     if (checks[rule].flag_cumulative === "N" && rule !== rule_description) {
                      //       checks[rule] = { ...checks[rule], checked: false }
                      //       }
                      //     })
                      //   }
                      if (flag_cumulative) {
                        Object.keys(checks).forEach((rule) => {
                          if (rule !== rule_description) {
                            checks[rule] = { ...checks[rule], checked: false }
                            }
                          })
                        }

                        checks[rule_description] = {
                          checked: !checks[rule_description]?.checked,
                          flag_cumulative,
                        };
                      setCheckedRules(checks)
                    }}
                    value="true"
                  />
                </TableCell>
              </TableRow>
              )
                : null 
            )
            ))}  
          </TableBody>
        </Table>
      </Box>
      <PerfectScrollbar id={"scrollbar"}>
        <Box sx={{ width: "100%", pr: 4 }}>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                {data.dates.map((date, i) => (
                  <TableCell
                    align={"center"}
                    key={date}
                    sx={{
                      backgroundColor: getColor(date),
                      minWidth: 64,
                      cursor: "pointer",
                    }}
                    onClick={(_) => handleClickColumn(i)}
                  >
                    <HeadColumn date={date} />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.tax.map((item) => (
                <TableRow key={item.acommodation_type}>
                  {data.dates.map((date) => (
                    <TableCell
                      padding={"none"}
                      align={"center"}
                      key={date}
                      sx={{ backgroundColor: getColor(date), padding: "6px 0", fontWeight: item[date] == undefined ? 400 : item[date].includes("BF") ? 700 : 400 }}
                    >
                      {item[date]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            <TableHead>
              <TableRow>
                {data.dates.map((date) => (
                  <TableCell
                    height={49.5}
                    align={"center"}
                    key={date}
                    sx={{ backgroundColor: getColor(date), minWidth: 64 }}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.prices.map((item) => (
                <TableRow key={item.acommodation_description}>
                  {data.dates.map((date) => (
                    <TableCell
                      padding={"none"}
                      align={"center"}
                      key={date}
                      sx={{
                        backgroundColor: getColor(date, item[date]),
                        padding: "6px 0",
                        verticalAlign: "text-top",
                        fontWeight: item[date]?.tax_ds === undefined 
                          ? 400 
                          : item[date]?.tax_ds.includes('BF') || item[date]?.daily_price === 0 
                            ? 700 
                            : 400
                      }}
                    >
                      {item[date]?.daily_price || 0}
                      <br />
                      <span style={{ fontSize: "11px" }}>
                        {item[date]?.min_room_nights}{" "}
                      </span>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>

            <TableHead>
              <TableRow>
                {data.dates.map((date) => (
                  <TableCell
                    height={49.5}
                    align={"center"}
                    key={date}
                    sx={{ backgroundColor: getColor(date), minWidth: 64 }}
                  />
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
            {data.rules.map((item) => 
              item.rule_description !== "." ? (
                <TableRow key={item.acommodation_description}>
                  {data.dates.map((date) => (
                    <TableCell
                      padding={"none"}
                      align={"center"}
                      key={date}
                      sx={{
                        backgroundColor: getColor(date, item[date]),
                        padding: "6px 0",
                      }}
                    >
                      {item[date]?.rule_id ? (
                        <CheckCircleIcon sx={{ height: 34, fill: "green" }} />
                      ) : (
                        ""
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ) : null
            )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
};

export default FormList;
