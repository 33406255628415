import ReactHTMLEmail, { Email, Item, renderEmail } from 'react-html-email'
import {
    Card,
    CardContent,
    Table, TableBody,
} from "@material-ui/core";
import {format} from "date-fns";
import './font.css';


ReactHTMLEmail.configStyleValidator({
    // When strict, incompatible style properties will result in an error.
    strict: true,

    // Whether to warn when compatibility notes for a style property exist.
    warn: true,

    // Platforms to consider for compatibility checks.
    platforms: [
        'gmail',
        'gmail-android',
        'apple-mail',
        'apple-ios',
        'yahoo-mail',
        'outlook',
        'outlook-legacy',
        'outlook-web',
    ],
})

const font = {
    fontFamily: 'sans-serif',
}

export const generateEmailTemplate = (clientName, budgets, emailText, hash) => {

    const formatPrice = (price) => 
        new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
    }).format(price);

   const splitted = emailText && emailText.split('{{{Budget}}}')
   if (splitted && splitted.length > 0) {
       const header = splitted[0]
       const body = splitted[1]

       const budget = renderEmail(
            <Item>
                <CardContent>
                    {budgets.map((budget, i) => (
                        <Card key={i} variant="outlined" style={{ marginTop: i > 0 ? 45 : 0}}>
                            <CardContent style={{ textAlign: 'center' }}>
                                <br /><br /><br/>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        textAlign: 'center',
                                    }}
                                >
                                    <p
                                        className={'font'}
                                        style={{
                                            fontSize: 22,
                                            fontWeight: 900,
                                            color: "#034ea2",
                                            textAlign: 'center',
                                            margin: 'auto',
                                        }}
                                    >
                                        Entrada: {format(budget.check_in, "dd/MM/yyyy")}{"  "}
                                        Saida: {format(budget.check_out, "dd/MM/yyyy")}
                                    </p>
                                </div>
                                <p
                                    className={'font'}
                                    style={{
                                        fontSize: 18,
                                        fontWeight: 400,
                                        color: "#495057",
                                        textAlign: 'center',
                                    }}
                                >
                                    {budget.adults_quantity} Adultos -{" "}
                                    {budget.children_quantity + budget.babies_quantity}
                                    {(budget.children_quantity + budget.babies_quantity) > 1
                                        ? " Crianças" : " Criança"}
                                </p>

                                <p
                                    className={'font'}
                                    style={{
                                        fontSize: 18,
                                        fontWeight: 400,
                                        color: "#495057",
                                        textAlign: 'center',
                                    }}
                                >
                                    {budget.babies_quantity > 1 ?
                                        `${budget.babies_quantity} crianças de 0 a 3 anos` :
                                        budget.babies_quantity === 0 ? "" :
                                        `${budget.babies_quantity} criança de 0 a 3 anos`}

                                    {budget.babies_quantity >= 1 && budget.children_quantity >= 1 ? ' e ' : ''}
                                    
                                    {budget.children_quantity > 1 ?
                                        ` ${budget.children_quantity} crianças de 4 a 11 anos` :
                                        budget.children_quantity === 0 ? "" :
                                        ` ${budget.children_quantity} criança de 4 a 11 anos`}
                                </p>

                                <p
                                    className={'font'}
                                    style={{
                                        fontSize: 18,
                                        fontWeight: 400,
                                        color: "#343A40",
                                    }}
                                >
                                    <strong style={{textAlign: 'center', fontSize: '20px'}}>
                                        Total do Pacote
                                    </strong>(diárias + extras + taxas)
                                </p>
                                <Table style={{ mt: 2, margin: 'auto', width:'600px'}}>
                                    <TableBody>
                                        {budget.prices.map((price, i) => (
                                            <tr
                                                style={{
                                                    backgroundColor: i % 2 === 0 ? '#e5e5e5' : '#fff',
                                                }}
                                                key={price.acommodation_description}
                                            >
                                                <td align={"left"} style={{width: '180px', fontSize: '18px'}}>
                                                    {price.acommodation_description}
                                                </td>
                                                <td align={"right"} style={{width: '100px', fontSize: '18px'}}>
                                                    {formatPrice(price.total_cost_accomodation)}
                                                </td>
                                            </tr>
                                        ))}
                                    </TableBody>
                                </Table>

                                <p
                                    className={'font'}
                                    style={{
                                        fontSize: 22,
                                        fontWeight: 400,
                                        color: "#343A40",
                                    }}
                                >
                                    Pagamentos à vista (depósito ou Pix) ainda ganham 5% de Desconto!
                                </p>
                                {budget.extras > 0 &&
                                <p
                                    className={'font'}
                                    style={{
                                        fontSize: 22,
                                        fontWeight: 400,
                                        color: "#343A40",
                                    }}
                                >
                                        Total de extras no pacote: { formatPrice(budget.extras)}
                                    </p>
                                }
                                {budget.children_quantity > 0 && 
                                budget.children_free > 0 && 
                                budget.min_room_night_children_free &&
                                <p
                                    className={'font'}
                                    style={{
                                        fontSize: 22,
                                        fontWeight: 400,
                                        color: "#343A40",
                                    }}
                                >
                                        Este orçamento contempla até {budget.children_free} crianças FREE
                                    </p>
                                }
                            </CardContent>
                        </Card>
                    ))}
                    {/* <br />
                    <div style={{with:"100%", textAlign:'center'}}>
                    <a href={"https://paydev.costao.com.br?hash="+hash}>{hash}</a>
                    </div> */}
                    <br /><br/>
                </CardContent>
            </Item>
       ).toString()

       const email = `${header.replace('{{{nome}}}', clientName)}${budget}${body}`
       return email
   }
}