import { v4 as uuidv4 } from 'uuid';

export default function generateUniqueHash(randomLength = 28, uuidLength = 7) {

  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  
  let randomPart = '';
  

  const randomBytes = new Uint8Array(randomLength);
  window.crypto.getRandomValues(randomBytes);
  
  for (let i = 0; i < randomLength; i++) {
    randomPart += characters.charAt(randomBytes[i] % characters.length);
  }

  const uuidPart = uuidv4().replace(/-/g, '').substring(0, uuidLength);

  return randomPart + uuidPart;
}